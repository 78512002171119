<template>
  <v-container>
    <v-row>
      <v-col cols="12" sm="12" md="6" class="mx-auto">
        <div
          v-if="!save && user.id_donor_type == 2 && user.goal_group == '0.00'"
        >
          <v-card color="amarillo">
            <v-form>
              <v-card-title>Información Adicional</v-card-title>
              <v-card-subtitle
                >Por favor, ingrese los datos solicitados, para luego poder
                realizar donaciones de manera frecuente</v-card-subtitle
              >
              <v-card-text>
                <v-row>
                  <v-col>
                    <v-text-field
                      label="Cantidad con la que se compromete a donar anualmente"
                      required
                      outlined
                      persistent-hint
                      hint="Recuerde la cantidad mínima de donación anual es de $5.00"
                      prepend-inner-icon="mdi-currency-usd"
                      color="black"
                      v-model="form.goal_group"
                    ></v-text-field>
                    <p class="mt-5 mb-0">
                      <b>Nota:</b> Una vez ingresada su cantidad de promesa
                      anual, esta no podrá ser modificada más tarde, en dado
                      caso desea cambiar dicha cantidad, por favor comunicarse
                      con su coordinador de grupo
                    </p>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <v-btn
                  color="black"
                  text
                  outlined
                  :loading="loading"
                  @click="formGroup"
                >
                  <v-icon class="mr-2">mdi-content-save</v-icon>
                  Guardar información
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </div>

        <div v-else-if="user.goal_group != null">
          <v-row class="d-flex flex-row">
            <v-col>
              <v-card>
                <v-card-title>¡Bienvenido, {{ user.name }}!</v-card-title>
                <v-card-subtitle>Te esperabamos de nuevo</v-card-subtitle>
              </v-card>
            </v-col>
            <v-col>
              <v-card>
                <v-card-title>Grupo: {{ group.group }}</v-card-title>
                <v-card-subtitle
                  >Descripcion: {{ group.description }}</v-card-subtitle
                >
              </v-card>
            </v-col>
          </v-row>
        </div>

        <div v-else class="my-5">
          <div class="d-flex align-center justify-center">
            <v-card>
              <v-card-title
                >¡Gracias por completar su información!</v-card-title
              >
              <v-card-subtitle
                >Ahora ya puede realizar donaciones y muchas gracias por tu
                donaciones</v-card-subtitle
              >
              <v-card-text>
                <p class="text-caption">
                  <b
                    >Nota: Si no puede ver el nuevo boton en el menu de
                    navegación, por favor recargue la página</b
                  >
                </p>
              </v-card-text>
            </v-card>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  name: 'HomeDonor',
  data: () => ({
    form: {},
    groups: [],
    months: [],
    monthsFormat: [],
    selectedGroup: {},
    group: {},
    loading: false,
    save: false,
  }),
  mounted() {
    this.getGroup();
    this.welcome();
  },
  methods: {
    ...mapActions({
      getDonor: 'Donor/getDonor',
      openSnackbar: 'Snackbar/openSnackbar',
    }),

    getGroup: function() {
      this.$http
        .get(this.api + 'group/' + this.user.id_group)
        .then(({ data }) => {
          this.group = data;
        });
    },

    welcome: function() {
      // if (this.user.frequent_donation == null) {
      // this.getGroups();
      // this.getMonths();
      // this.form.id_group = this.user.id_group;
      // }
    },

    // getGroups: async function() {
    //   await this.$http
    //     .get(this.api + 'group')
    //     .then(({ data }) => {
    //       this.groups = data;
    //     })
    //     .catch((err) => {
    //       console.error('Error al obtener Grupos de Donadores: ' + err);
    //     });
    // },

    // getMonths: function() {
    //   let oneMonth = 'Cada 1 Mes';
    //   let month;
    //   this.months.push(oneMonth);
    //   this.monthsFormat.push(month);
    //   for (let index = 2; index <= 12; index++) {
    //     month += this.months.push({
    //       text: `Cada ${index} Meses`,
    //       value: `${index} Meses`,
    //     });
    //   }
    // },

    formGroup: function() {
      let validate = this.form.goal_group;

      if (validate) {
        if (this.form.goal_group >= 5) {
          this.saveGoalGroup();
          this.loading = false;
          this.save = true;
        } else {
          this.openSnackbar({
            type: 'danger',
            message: 'Por favor ingrese una cantidad correcta y no menos de $5',
          });
        }
      } else {
        this.openSnackbar({
          type: 'danger',
          message: 'Por favor, no deje el campo vacio',
        });
      }
    },

    saveGoalGroup: async function() {
      this.loading = true;
      await this.$http
        .post(this.api + 'donor_group/save_group', this.form)
        .then(({ data }) => {
          this.loading = false;
          this.openSnackbar({
            type: 'success',
            message: data.message,
          });
        })
        .catch((err) => {
          this.openSnackbar({
            type: 'danger',
            message: 'Al parecer hay un error al conectarse con el servidor',
          });
        });
    },

    // slcGroup: function() {
    //   //filtra todos los que sean diferentes a la id seleccionada (filtrar = convervar)
    //   let find = this.groups.filter(
    //     (group) => group.id_group == this.form.id_group
    //   );
    //   //hay algo? entonces la posicion 0 sera el seleccionado
    //   this.selectedGroup = find.length > 0 ? find[0] : null;
    // },
  },
  computed: {
    ...mapGetters({
      api: 'getApi',
      img: 'getImg',
      token: 'Donor/getToken',
      user: 'Donor/getDonor',
    }),
  },
};
</script>

<style></style>
